import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCk7bPjt7QEwIDXjeTRYsgDIrgMXHXVQN8",
  authDomain: "toezio.firebaseapp.com",
  databaseURL: "https://toezio-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "toezio",
  storageBucket: "toezio.appspot.com",
  messagingSenderId: "560532090762",
  appId: "1:560532090762:web:c1f888ee866cd8c307a273",
  measurementId: "G-B5JRCBJ34Y",
};

firebase.initializeApp(firebaseConfig);
firebase.auth();

firebase.analytics();

// storage
export const storage = firebase.storage();
export const storageRef = storage.ref();

export const uploadRefs = storageRef.child("uploads");
// db
const db = firebase.firestore();
export const firebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp;

export const nodesCollection = db.collection("nodes");

export const firebaseLooper = (snapshot) => {
  let data = [];
  if (snapshot) {
    if (typeof snapshot.forEach === "function") {
      try {
        snapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
            //dtVrs: doc?.dteVrs?.toDate(),
          });
        });
      } catch (error) {
        //console.log(error);
      }
    } else {
      const snapshotdata = snapshot.data();
      if (snapshotdata) {
        data = {
          ...snapshotdata,
          id: snapshot.id,
          //dtVrs: snapshot?.dteVrs?.toDate(),
        };
      }
    }
  }

  return data;
};

export default firebase;

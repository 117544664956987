import React, { Component } from "react";
import { Router, BrowserRouter } from "react-router-dom";
import history from "../router/history";
import Navigation from "./navigation/Navigation";
import Spinner from "../spinner";
import _ from "lodash";

export const AppContext = React.createContext({});

export default class AppContextHandler extends Component {
  state = {
    auth: null,
    navigation: null,
    cart: null,
    notifications: null,
    timestamp: Date.now(),
    browser: null,
  };

  constructor(props) {
    super(props);
    this.setCurrentProject = this.setCurrentProject.bind(this);
  }

  handleNavContext = (navContext) => {
    if (this.state.navigation !== navContext) {
      this.setState({ navigation: navContext });
    }
  };

  handleBrowserContext = (browserContext) => {
    if (this.state.browser !== browserContext) {
      this.setState({ browser: browserContext });
    }
  };

  renderPage = () => {
    if (this.state.navigation) {
      return <> {this.props.children} </>;
    }
    return <Spinner visible={true} />;
  };

  setCurrentProject(project) {
    if (project?.id !== this.state.project?.id) {
      console.log("AppContex  debounce setCurrentProject", project?.N);
      this.setState({ project: project });
    }
  }

  renderContext = () => {
    return (
      <AppContext.Provider value={{ ...this.state, setCurrentProject: this.setCurrentProject }}>
        <Navigation handleNavContext={this.handleNavContext} handleBrowserContext={this.handleBrowserContext} auth={this.state.auth}></Navigation>
        {this.renderPage()}
      </AppContext.Provider>
    );
  };

  render() {
    return (
      <Router history={history}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>{this.renderContext()}</BrowserRouter>
      </Router>
    );
  }
}

import React from "react";
import { withRouter } from "react-router-dom";
import { fetchPages } from "../../router/Navigation.Services";
import { getCurrentPage, urlMatch } from "../../../../utilities/url";

//import sendTrackingEvent from "../../../../utilities/tracking/tracking";
import { getBrowserDetails } from "../../../../utilities/tracking/browserDetection";

class Navigation extends React.Component {
  state = { pages: null, currentPage: null };

  componentDidMount() {
    this._isMounted = true;
    this.getPages();
    this.props.history.listen((location) => {
      this.updateNavState(this.state.pages);
    });
    this.pageTimeWatcher = Date.now();
    this.getBrowser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  pageTimeWatcher = {};

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location || this.state.pages !== prevState.pages) {
      this.updateNavState(this.state.pages);
      this.sendNavigationEvent();
    }
  }

  sendNavigationEvent() {
    let ellapsedMS = Date.now() - this.pageTimeWatcher;
    this.pageTimeWatcher = Date.now();
    // sendTrackingEvent("Page load: " + this.props.location.pathname, "Navigation", "Page load", { origin: window.location.origin, ellapsedMS });
  }

  async getPages() {
    const pages = await fetchPages();
    this.setState({ pages: pages });
  }

  getBrowser() {
    const browser = getBrowserDetails();
    this.props.handleBrowserContext(browser);
  }

  prevPage = null;
  updateNavState(pages) {
    if (this._isMounted) {
      let currentPage = getCurrentPage(pages);
      if (currentPage && currentPage.url) {
        this.prevPage = !urlMatch(currentPage.url, this.props.location.pathname) ? this.props.location : this.prevPage;
      }
      const navContext = { pages: pages, currentPage, previousPage: this.prevPage };
      this.props.handleNavContext(navContext);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(Navigation);

import { nodesCollection, firebaseLooper } from "./firebase";

//TODO
//firebase looper adds each readed node

const setCacheQuery = (queryId, result) => {
  localStorage.setItem(queryId, JSON.stringify(result));
};

const getCachedQuery = (queryId) => {
  if (true) {
    const cachedResult = localStorage.getItem(queryId);
    if (cachedResult) {
      return JSON.parse(cachedResult);
    }
  }
};

const clearRelatedCache = (node, queryId) => {
  localStorage.removeItem("getChildrenNodes:" + node.parentId); //remove siblings from parent
  localStorage.removeItem("getChildrenNodes:" + node.id); //remove children  from saved node
  localStorage.removeItem("getNodeById:" + node.id); //remove node
  localStorage.removeItem("getNodeByTypeAndSubtype:" + node.T + "." + node.ST);
  localStorage.removeItem("getNodeByType:" + node.T);
  localStorage.removeItem("getNodeBySubtype:" + node.ST);

  if (node.ST === "ACTIONSFOLDER") {
    localStorage.removeItem("getChildrenBySubType:" + node.parentId + "-ACTIONSFOLDER");
    localStorage.removeItem("getChildrenByType:" + node.id + "-ACTION");
  }
  if (node.T === "ACTION") {
    localStorage.removeItem("getChildrenByType:" + node.parentId + "-ACTION");
  }
};

export const getNodeById = async (id) => {
  if (id) {
    const queryId = "getNodeById:" + id;
    let node = undefined;
    let cachedResult = getCachedQuery(queryId);
    if (cachedResult && cachedResult.dteVrs) {
      //try to find a newer version
      await nodesCollection
        .where("dteVrs", ">", cachedResult.dteVrs)
        .where("id", "==", cachedResult.id)
        .get()
        .then((snapshot) => {
          node = firebaseLooper(snapshot);
          if (node?.length) {
            //console.log("New node version found", node);
            cachedResult = node[0];
            setCacheQuery(queryId, node);
          }
        });
      return cachedResult;
    } else {
      //console.log(queryId);
      await nodesCollection
        .doc(id)
        .get()
        .then((snapshot) => {
          node = firebaseLooper(snapshot);
          setCacheQuery(queryId, node);
        });
      return node;
    }
  }
};

export const saveNode = async (nodeData, id) => {
  const queryId = "saveNode:" + nodeData;
  id = id ? id : nodeData.id;
  nodeData.dteVrs = Date.now();

  //console.log(queryId, nodeData);
  await nodesCollection
    .doc(id)
    .set(nodeData)
    .then((snapshot) => {
      //node = firebaseLooper(snapshot);
      clearRelatedCache(nodeData);
    });
  return nodeData;
};

export const archiveNode = async (nodeData, archiveDescendants) => {
  //TODO  copy nodes into archive sotorage
  const queryId = "archiveNode:" + nodeData.N;
  const id = nodeData.id;
  if (id) {
    nodeData.dteVrs = Date.now();

    //console.log(queryId, nodeData);
    if (archiveDescendants) {
      const childrenNodes = await getChildrenNodes(nodeData.id);
      if (childrenNodes && childrenNodes.length) {
        childrenNodes.forEach((childrenNode) => {
          archiveNode(childrenNode, true);
        });
      }
    }

    await nodesCollection
      .doc(id)
      .delete()
      .then((snapshot) => {
        //console.log(queryId);
        // node = firebaseLooper(snapshot);
        clearRelatedCache(nodeData);
      });
    return nodeData;
  }
};

export const duplicateNode = async (originalNode, duplicateDescendants, newParentId = null) => {
  //TODO  copy nodes into archive sotorage
  const queryId = "duplicateNode:" + originalNode.id;
  const originalId = originalNode?.id;
  if (originalId) {
    const nodeName = !newParentId ? originalNode.N + " copy" : originalNode.N;

    const newNodeData = newParentId
      ? { ...originalNode, N: nodeName, id: null, createdAt: parseInt(Date.now()), dteVrs: parseInt(Date.now()), parentId: newParentId }
      : { ...originalNode, N: nodeName, id: null, createdAt: parseInt(Date.now()), dteVrs: parseInt(Date.now()) };

    const newNodeId = await createNode(newNodeData);
    if (duplicateDescendants) {
      const originalChildren = await getChildrenNodes(originalId);
      if (originalChildren && originalChildren.length) {
        await Promise.all(
          originalChildren.map(async (originalChildren) => {
            if (originalChildren) {
              return await duplicateNode(originalChildren, true, newNodeId);
            }
          })
        );
      }
    }

    //console.log(queryId);
    clearRelatedCache(originalNode);
    clearRelatedCache(newNodeData);
    return newNodeId;
  }
};

export const createNode = async (nodeData) => {
  const queryId = "createNode:" + nodeData?.id;
  const name = nodeData.N ? nodeData.N : "New " + nodeData.ST.toLowerCase() + " " + nodeData.T.toLowerCase();
  //console.log("createNode", queryId);
  nodeData = { ...nodeData, createdAt: parseInt(Date.now()), dteVrs: parseInt(Date.now()), N: name };
  let id = undefined;
  await nodesCollection.add(nodeData).then((docRef) => {
    id = docRef.id;

    clearRelatedCache(nodeData);
  });
  return { ...nodeData, id: id };
};

export const getChildrenNodes = async (id) => {
  const queryId = "getChildrenNodes:" + id;
  if (id) {
    // const cachedResult = getCachedQuery(queryId);
    // if (cachedResult) {
    //   return cachedResult;
    // } else {
    //console.log(queryId);
    let nodes = undefined;
    await nodesCollection
      .where("parentId", "==", id)
      .get()
      .then((snapshot) => {
        nodes = firebaseLooper(snapshot);
        nodes = nodes.sort(function (a, b) {
          var nameA = a["order"],
            nameB = b["order"];
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        setCacheQuery(queryId, nodes);
      });
    return nodes;
    // }
  }
};

export const getParentNode = async (parentId) => {
  if (parentId) {
    return await getNodeById(parentId);
  }
};

export const getNodeByProperty = async (property, value, operator = "==") => {
  const queryId = "getNodeByProperty:" + property + "-" + value + "-" + operator;
  const cachedResult = getCachedQuery(queryId);
  if (cachedResult) {
    return cachedResult;
  } else {
    //console.log(queryId);
    let node = undefined;
    await nodesCollection
      .where(property, operator, value)
      .get()
      .then((snapshot) => {
        node = firebaseLooper(snapshot);
        setCacheQuery(queryId, node);
      });
    return node;
  }
};

export const getNodeByType = async (type = null) => {
  const queryId = "getNodeByType:" + type;
  const cachedResult = getCachedQuery(queryId);

  // if (cachedResult && cachedResult.dteVrs) {
  //   //try to find a newer version
  //   await nodesCollection
  //     .where("dteVrs", ">", cachedResult.dteVrs)
  //     .where("T", "==", type)
  //     .get()
  //     .then((snapshot) => {
  //       node = firebaseLooper(snapshot);
  //       if (node?.length) {
  //         //console.log("New node version found", node);
  //         cachedResult = node[0];
  //         setCacheQuery(queryId, node);
  //       }
  //     });
  //   return cachedResult;

  // } else {
  let node = undefined;
  //console.log(queryId);
  await nodesCollection
    .where("T", "==", type)
    .get()
    .then((snapshot) => {
      node = firebaseLooper(snapshot);
      setCacheQuery(queryId, node);
    });

  return node;
  // }
};

export const getNodeBySubType = async (subtype = null) => {
  const queryId = "getNodeBySubType:" + subtype;
  const cachedResult = getCachedQuery(queryId);
  if (cachedResult) {
    return cachedResult;
  } else {
    let node = undefined;
    //console.log(queryId);
    await nodesCollection
      .where("ST", "==", subtype)
      .get()
      .then((snapshot) => {
        node = firebaseLooper(snapshot);
        setCacheQuery(queryId, node);
      });

    return node;
  }
};

export const getNodeByTypeAndSubtype = async (type = null, subtype = null) => {
  const queryId = "getNodeByTypeAndSubtype:" + type + "." + subtype;
  const cachedResult = getCachedQuery(queryId);
  if (cachedResult) {
    return cachedResult;
  } else {
    //console.log(queryId);
    let node = undefined;
    if (type && subtype) {
      await nodesCollection
        .where("T", "==", type)
        .where("ST", "==", subtype)
        .get()
        .then((snapshot) => {
          node = firebaseLooper(snapshot);
          setCacheQuery(queryId, node);
        });
    }
    return node;
  }
};

export const getChildrenBySubType = async (subtype = null, parentId = null) => {
  const queryId = "getChildrenBySubType:" + parentId + "-" + subtype;
  const cachedResult = getCachedQuery(queryId);
  if (cachedResult) {
    return cachedResult;
  } else {
    //console.log(queryId);
    let node = undefined;
    await nodesCollection
      .where("ST", "==", subtype)
      .where("parentId", "==", parentId) //5cbca6a2f7d94a3d30533079
      .get()
      .then((snapshot) => {
        node = firebaseLooper(snapshot);
        setCacheQuery(queryId, node);
      });
    return node;
  }
};

export const getChildrenByType = async (type = null, parentId = null) => {
  const queryId = "getChildrenByType:" + parentId + "-" + type;
  const cachedResult = getCachedQuery(queryId);
  if (cachedResult) {
    return cachedResult;
  } else {
    //console.log(queryId);
    let node = undefined;
    await nodesCollection
      .where("T", "==", type)
      .where("parentId", "==", parentId)
      .get()
      .then((snapshot) => {
        node = firebaseLooper(snapshot);
        setCacheQuery(queryId, node);
      });
    return node;
  }
};

export const getAllNodes = async () => {
  const queryId = "getAllNodes";
  const cachedResult = getCachedQuery(queryId);
  if (cachedResult) {
    return cachedResult;
  } else {
    //console.log(queryId);
    let nodes = undefined;
    await nodesCollection.get().then((snapshot) => {
      nodes = firebaseLooper(snapshot);
      setCacheQuery(queryId, nodes);
    });
    return nodes;
  }
};

export const getNodeDefinition = async (type, subtype) => {
  if (type && subtype) {
    const queryId = "getNodeDefinition:" + type + "-" + subtype;
    let node = undefined;
    let cachedResult = getCachedQuery(queryId);
    if (cachedResult && cachedResult.dteVrs) {
      //try to find a newer version
      await nodesCollection
        .where("dteVrs", ">", cachedResult.dteVrs)
        .where("ST", "==", "DEFINITION")
        .where("d_type", "==", type)
        .where("d_subtype", "==", subtype)
        .get()
        .then(async (snapshot) => {
          node = firebaseLooper(snapshot);
          if (node?.length) {
            //console.log("New node version found", node);
            cachedResult = node[0];
            setCacheQuery(queryId, node);
          }
        });
      return cachedResult;
    } else {
      //console.log(queryId);
      await nodesCollection
        .where("ST", "==", "DEFINITION")
        .where("d_type", "==", type)
        .where("d_subtype", "==", subtype)
        .get()
        .then(async (snapshot) => {
          const results = firebaseLooper(snapshot);
          node = results?.[0];
          setCacheQuery(queryId, node);
        });
      return node;
    }
  }
};

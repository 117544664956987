import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import "assets/styles/main.scss";
import { AppContext } from "./components/master/context/App.Context";
import { getBrowserDetails } from "utilities/tracking/browserDetection";

const Router = lazy(() => import("components/master/router/Router.Main"));
const Header = lazy(() => import("components/master/header/header"));
const Footer = lazy(() => import("components/master/footer/footer"));
const renderLoader = () => <p>Loading</p>;

class App extends React.Component {
  state = { sidebarDisplayed: true, sidebarDisplayedMobile: false };

  toggleNavBar = () => {
    this.setState({ sidebarDisplayed: !this.state.sidebarDisplayed });
  };

  toggleNavBarOnClickMobile = () => {
    this.setState({
      sidebarDisplayedMobile: !this.state.sidebarDisplayedMobile,
    });
  };

  render() {
    const browser = getBrowserDetails();
    const browserName = browser.browser ? browser.browser : "default";
    const browserVersion = browser.browserMajorVersion ? browser.browserMajorVersion : "default";
    const currentPage = this.context?.navigation?.currentPage;
    const currentPageType = currentPage?.ST?.toLowerCase();

    return (
      <>
        <Helmet>
          <title>Toezio - {currentPage.N}</title>
          <meta
            name="description"
            content={currentPage.metadescription ? currentPage.metadescription : "Toezio is a Madrid-based visual artist and graphic maker with a focus on Lettering and Custom Typography."}
          />
        </Helmet>

        <Suspense fallback={renderLoader()}>
          <div className={"container-wrapper " + currentPageType} data-browser={browserName} data-version={browserVersion}>
            <Header toggleNavBar={() => this.toggleNavBar()} toggleNavBarOnClickMobile={this.toggleNavBarOnClickMobile} />
            <div className="content-container">
              <Router />
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

App.contextType = AppContext;
export default App;

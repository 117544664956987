import React from "react";

class Spinner extends React.Component {
  render() {
    if (this.props.visible) {
      return (
        <div
          className="spinner-border"
          style={{
            width: "3rem",
            height: "3rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            margin: "-1.5rem",
          }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Spinner;

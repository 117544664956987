import { pages } from "./PagesList";
import { nodesCollection, firebaseLooper } from "api/firebase";
import { getNodeByType } from "api/Nodes";

/*Returns the list of pages*/
export const fetchPages = async () => {
  const pages = await getNodeByType("PAGE");

  //process urls
  var data = pages.map((a) => ({
    ...a,
    url: getPageRelativeURL(a, pages),
  }));
  return data;
};

/*Returns the list of elements displayed in the main nav*/
export const getMainNavItems = (pages) => {
  var data = pages.filter((a) => a.showInNav);
  return data;
};

export const getSecondaryNavItems = (pages) => {
  var data = pages.filter((a) => a.showInSecondaryNav);
  return data;
};

/*Generates the URL of the page for navigation propouses*/
export const getPageRelativeURL = (navItem, allPages) => {
  var url = "";
  var parentPage = getParentPage(navItem, allPages);
  if (parentPage != null) {
    url = getPageRelativeURL(parentPage, allPages);
  }
  if (typeof navItem.nicename === "undefined") {
    return url + "/" + navItem.id;
  } else {
    if (navItem.nicename !== "/") {
      return url + "/" + navItem.nicename;
    } else {
      return url + "/";
    }
  }
};

/*retrieves the inmediate parent page*/
export const getParentPage = (navItem, allPages) => {
  if (navItem && navItem.parentId !== null) {
    var parentCandidates = allPages.filter((a) => a.id === navItem.parentId);
    if (parentCandidates && parentCandidates.length) {
      return parentCandidates[0];
    }
  }
  return null;
};
